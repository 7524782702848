@use "../styles/utils";

$mobile: '@media (max-width: 580px)';

.root {
  display: block;
  background-color: #00b5e2;
  font-family: roboto-regular, sans-serif;
}

.container {
  padding: 32px 8px 48px;
  display: flex;

  @media (min-width: 581px) {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media (min-width: 768px) {
    max-width: 94%;
    margin: 0 3%;
  }

  @media (min-width: 992px) {
    max-width: 90%;
    margin: 0 5%;
  }

  @media (min-width: 1200px) {
    max-width: 1080px;
    margin: 0 auto;
  }

  @media (min-width: 1600px) {
    max-width: 1440px;
  }
}

.group {
  width: 25%;
}

.button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;

  > svg {
    display: none;
  }
}

.title {
  color: utils.$blue;
  font-size: 16px;
  margin: 0 0 8px;
}

.buttonLinks {
  list-style-type: none;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.buttonLink {
  > a {
    display: inline-block;
    padding: 7px 0;
    color: utils.$blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include utils.bp-any(sm) {
  .group {
    width: calc(100% / 3);
  }
}

@include utils.bp-any($mobile) {
  .container {
    flex-direction: column;
    padding: 0;
  }

  .group {
    width: 100%;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  .button {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid utils.$blue;
    border-width: 1px 0;
    padding: 16px;

    > svg {
      display: block;
      width: 12px;
      height: 12px;
      transition: all 0.25s ease-out;

      &[data-expanded="true"] {
        transform: rotate(180deg);
      }

      > path {
        fill: utils.$blue;
      }
    }
  }

  .title {
    margin: 0;
  }

  .buttonLinks {
    background-color: white;
    max-height: 0;
    overflow: hidden;
    padding: 0 16px;
    transition: all 0.25s ease-out;

    &[data-expanded="true"] {
      max-height: 500px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.yourPrivacyChoices {
  &__icon {
    margin-left: 0.5em;
  }

  &__image {
    vertical-align: middle;
    max-width: 29px;
    height: auto;
  }
}