@use '../styles/utils';

$short: "@media (max-height: 499px)";

.root {
  text-decoration: none;
  position: relative;
  display: block;
}

.container {
  position: relative;
  display: block;
  text-decoration: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  transition: border 0.1s ease-out, transform 0.1s ease-out;

  @include utils.bp-any(sm, md) {
    justify-content: center;

    @include utils.bp-any($short) {
      height: 40px;

      &::before {
        display: none !important;
      }
    }
  }

  &[data-active="false"] {
    background: #FFFFFF;
    border: solid 2px #002F6C;
    box-shadow: 0px 5px 0px #002F6C, inset 0px -5px 0px rgba(0, 131, 202, 0.2);

    .icon {
      svg > path {
        fill: #0085c6;
      }
    }

    .text {
      background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  &[data-active="true"] {
    background: #003169;
    border: solid 2px #001e41;
    box-shadow: 0px 5px 0px #001e41, inset 0px -5px 0px #002855;

    .icon {
      svg > path {
        fill: #00BFDC;
      }
    }

    .text {
      background: #FFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: url('../assets/components/Nav/drip.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 54px;
      height: 22px;
      bottom: -25px;
      left: 24px;
      transform-origin: top;
      animation: animationScale 0.3s ease-in-out;


      @include utils.bp-any(sm, md) {
        animation: none;
      }
    }
  }

  &:hover {
    border: 2px solid #002F6C;
    transform: scale(1.05);

    &[data-active="false"] {
      background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);

      .icon {
        svg > path {
          fill: #FFF;
        }
      }
      
      .text {
        background: #FFF;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  &:active {
    transform: scale(1);
  }
}

.text {
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  top: 0.1em;
  transition: all 0.3s ease-in-out;
}

.content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.icon {
  display: block;
  width: 24px;
  height: 24px;

  svg > path {
    transition: all 0.3s ease-in-out;
  }
}

@keyframes animationScale {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
