@use '../styles/utils';

.root {
  &[data-uppercase="true"] {
    text-transform: uppercase;
  }

  &[data-theme="blue"] {
    .inner {
      background: rgba(0, 191, 220, 0.1);
      background: linear-gradient(270deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%);
      color: #0083CA;
    }
  }

  &[data-relative-size="false"] {
    font-size: 16px;
  }
}

// The nesting of .outer and .inner is necessary to avoid unwanted underlines if parent element is a link
.outer {
  display: inline-block;
  text-decoration: none;
}

.inner {
  min-height: utils.num-em(30, 16);
  border-radius: 999px;
  font-weight: 400;
  font-family: 'Coiny', sans-serif;
  display: inline-flex;
}

.copy {
  padding: utils.num-em(8, 16) utils.num-em(16, 16);
  display: inline-flex;
  line-height: 1em;
  align-items: flex-start;
}

.root[data-icon="true"] .copy {
  padding-left: utils.num-em(6, 16);
}

.label {
  display: block;
  font-size: utils.num-em(12, 16);
}

.value {
  display: block;
  font-size: utils.num-em(12, 16);
}

.separator {
  margin-right: utils.num-em(4, 16);
}

.icon {
  width: 30px;
  height: 30px;
  background: linear-gradient(270.76deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%);
  border-radius: 30px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}