@use "../styles/utils";

.root {
  position: relative;

  @include utils.bp-any(sm) {
    width: 62px;
    height: 68px;
  }

  @include utils.bp-any(md, lg) {
    width: 94px;
    height: 100px;
  }
}

.button {
  cursor: pointer;
  border: none;
  appearance: none;
  background: transparent;
  padding: 0;
  transition: transform 0.1s ease-out;
  width: 100%;
  height: 100%;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-out;
  }

  &::before {
    background-image: url("../assets/components/PlayButton/play.png");
  }

  &::after {
    background-image: url("../assets/components/PlayButton/play-hover.png");
    opacity: 0;
  }

  &:hover {
    transform: scale(1.05);

    &::after {
      opacity: 1;
    }
  }

  &:active {
    transform: scale(1);
  }
}
