@use 'utils';
// Site uses ITCSS formatting for global styles https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
@import 'generic';
@import 'elements';
@import 'objects';
// Component stylesheet excluded since styling is handled by React
@import 'utilities';
@import 'trumps';

@import 'swiper/css';
@import 'swiper/scss/pagination';
@import "swiper/scss/effect-creative";

.swiper-pagination {
  position: static;
}

// Example Breakpoints
// See _tools.scss for available breakpoints
@include utils.bp-any(sm) {
  body {
    //background-color: red;
  }
}

@include utils.bp-any(md, lg) {
  body {
    //background-color: orange;
  }
}
