// Tools – Globally used mixins and functions.
@use 'settings';

// See corresponding Javascript version in `src/utils/breakpoints.js`
@forward '~@curiousmedia/breakpoint/src/_breakpoint.scss' as bp-* with (
  $breakpoints: (
    touch: '@media screen and (pointer: coarse)',
    cursor: '@media screen and (pointer: fine)',
    hover: '@media screen and (hover: hover)',
    still: '@media screen and (prefers-reduced-motion)',
    sm: '@media (max-width: 767px)',
    md: '@media (min-width: 768px) and (max-width: 1024px)',
    lg: '@media (min-width: 1025px)',
  )
);

@forward '~@curiousmedia/sass-numbers/sass-numbers';

@mixin no-margin-overflow() {
  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

@mixin animated-wave() {
  background: url(../assets/white-wave-down.png) center center repeat-x;
  background-size: auto 100%;
  :global {
    animation: animated-wave linear infinite 10s;
  }
}

@mixin blue-bg() {
  background:
    url('../assets/bg-blue-squishies.png'),
    linear-gradient(180deg, #0083ca80 0%, #00bfdc80 100%);
}