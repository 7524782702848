@use 'sass:math';

.card {
  aspect-ratio: 200 / 235;
  position: relative;
  z-index: 1;

  // List theme
  &[data-theme="list"] {
    .name {
      font-size: 16px;
    }

    .bg {
      mask-image: url('../assets/components/SquishieThumbnail/mask.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
      overflow: hidden;
    }

    // Not rare, not owned
    &[data-is-rare="false"][data-is-owned="false"] {
      .bg {
        background: radial-gradient(50% 40% at 50% 60%, rgba(0, 131, 202, 0.5) 0%, rgba(0, 131, 202, 0) 100%), #002F6C;
      }
    }

    // Not rare, owned
    &[data-is-rare="false"][data-is-owned="true"] {
      .bg {
        background: radial-gradient(50% 40% at 50% 60%, rgba(255, 238, 117, 0.5) 0%, rgba(255, 238, 117, 0) 100%), #FF6720;
      }
    }

    // Rare, not owned
    &[data-is-rare="true"][data-is-owned="false"] {
      .bg {
        background: radial-gradient(50% 40% at 50% 60%, rgba(0, 131, 202, 0.5) 0%, rgba(0, 131, 202, 0) 100%), #002F6C;
      }
    }

    // Rare, owned
    &[data-is-rare="true"][data-is-owned="true"] {
      .bg {
        background: radial-gradient(50% 40% at 50% 60%, rgba(255, 238, 117, 0.5) 0%, rgba(255, 238, 117, 0) 100%), #F6B100;
      }
    }
  }

  // Single theme
  &[data-theme="single"] {
    .bg {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .name {
      font-size: 30px;
    }

    &[data-is-rare="false"] {
      .bg {
        background-image: url(../assets/components/SquishieCard/bg-orange.png);
      }
    }

    &[data-is-rare="true"] {
      .bg {
        background-image: url(../assets/components/SquishieCard/bg-yellow.png);
      }
    }
  }

  // Not rare, not owned
  &[data-is-rare="false"][data-is-owned="false"] {
    color: #00BFDC;

    .shadow {
      background-color: #00326d;
    }
  }

  // Not rare, owned
  &[data-is-rare="false"][data-is-owned="true"] {
    color: #FFF;

    .shadow {
      background-color: #ff6a24;
    }
  }

  // Rare, not owned
  &[data-is-rare="true"][data-is-owned="false"] {
    color: #00BFDC;

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
    }

    &::after {
      background-image: url(../assets/components/SquishieThumbnail/rare-card-locked-overlay.png);
      z-index: 10;
    }

    .shadow {
      background-color: #00326d;
    }
  }

  // Rare, owned
  &[data-is-rare="true"][data-is-owned="true"] {
    color: #FFF;
    text-shadow: 0px 3px 0px rgba(255, 103, 32, 0.3);

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
    }

    &::before {
      background-image: url(../assets/components/SquishieThumbnail/rare-card-unlocked-underlay.png);
    }

    &::after {
      background-image: url(../assets/components/SquishieThumbnail/rare-card-unlocked-overlay.png);
      z-index: 10;
    }

    .shadow {
      background-color: #ffc55c;
    }
  }
}

.shadow {
  position: absolute;
  bottom: 18%;
  left: 15%;
  width: 70%;
  height: 18%;
  border-radius: 50%;
  background-blend-mode: multiply;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 5;
}

.name {
  height: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: 'Coiny', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.bg {
  width: 100%;
  height: 100%;
}

.rare {
  position: absolute;
  top: percentage(math.div(16, 235));
  left: percentage(math.div(-6, 200));
  width: percentage(math.div(55, 200));
  height: percentage(math.div(21, 235));
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.sparkle {
  position: absolute;
  top: 0;
  left: 0;
  width: math.percentage(math.div(26, 382));
  aspect-ratio: 1 / 1;
  display: block;
  z-index: 2;

  &::before {
    content: "";
    display: block;
    background: url(../assets/components/SquishieThumbnail/sparkle.png) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    transform: scale(0);
  }

  &--1 {
    top: 24%;
    left: 4%;
    animation: rotate 8s linear infinite;

    &::before {
      animation: twinkle 5s ease-in-out infinite;
    }
  }

  &--2 {
    top: 19%;
    left: 13%;
    animation: rotate 4s linear infinite;

    &::before {
      animation: twinkle 4s ease-in-out infinite 1s;
    }
  }

  &--3 {
    top: 6%;
    left: 38%;
    animation: rotateR 8.5s linear infinite;

    &::before {
      animation: twinkle 3.5s ease-in-out infinite 1s;
    }
  }

  &--4 {
    top: 12%;
    left: 38%;
    animation: rotate 6s linear infinite;

    &::before {
      animation: twinkle 4.5s ease-in-out infinite 2s;
    }
  }

  &--5 {
    top: 10%;
    left: 49%;
    animation: rotateR 3.75s linear infinite;

    &::before {
      animation: twinkle 6.5s ease-in-out infinite 1s;
    }
  }

  &--6 {
    top: 4%;
    left: 53%;
    animation: rotate 4.9s linear infinite;

    &::before {
      animation: twinkle 7s ease-in-out infinite 1s;
    }
  }

  &--7 {
    top: 11%;
    left: 56%;
    animation: rotateR 3.8s linear infinite;

    &::before {
      animation: twinkle 2.5s ease-in-out infinite 2s;
    }
  }

  &--8 {
    top: 17%;
    left: 86%;
    animation: rotate 9s linear infinite;

    &::before {
      animation: twinkle 6.25s ease-in-out infinite 1.25s;
    }
  }

  &--8 {
    top: 17%;
    left: 86%;
    animation: rotate 18s linear infinite;

    &::before {
      animation: twinkle 4.5s ease-in-out infinite 3s;
    }
  }

  &--9 {
    top: 23%;
    left: 82%;
    animation: rotateR 6s linear infinite;

    &::before {
      animation: twinkle 6.75s ease-in-out infinite 2.25s;
    }
  }

  &--10 {
    top: 31%;
    left: 82%;
    animation: rotate 15s linear infinite;

    &::before {
      animation: twinkle 4.6s ease-in-out infinite 2.5s;
    }
  }

  &--11 {
    top: 40%;
    left: 73%;
    animation: rotateR 7s linear infinite;

    &::before {
      animation: twinkle 8.6s ease-in-out infinite 3.5s;
    }
  }

  &--12 {
    top: 45%;
    left: 80%;
    animation: rotateR 12s linear infinite;

    &::before {
      animation: twinkle 9.2s ease-in-out infinite 6.5s;
    }
  }

  &--13 {
    top: 51%;
    left: 76%;
    animation: rotate 11s linear infinite;

    &::before {
      animation: twinkle 11.2s ease-in-out infinite 4.5s;
    }
  }

  &--14 {
    top: 61%;
    left: 80%;
    animation: rotate 6s linear infinite;

    &::before {
      animation: twinkle 7.5s ease-in-out infinite 4.5s;
    }
  }

  &--15 {
    top: 67%;
    left: 86%;
    animation: rotate 4s linear infinite;

    &::before {
      animation: twinkle 12s ease-in-out infinite 3.5s;
    }
  }

  &--16 {
    top: 70%;
    left: 74%;
    animation: rotate 8s linear infinite;

    &::before {
      animation: twinkle 8.6s ease-in-out infinite 2.5s;
    }
  }

  &--17 {
    top: 69%;
    left: 68%;
    animation: rotateR 10s linear infinite;

    &::before {
      animation: twinkle 5.6s ease-in-out infinite 3.5s;
    }
  }

  &--18 {
    top: 80%;
    left: 82%;
    animation: rotate 5s linear infinite;

    &::before {
      animation: twinkle 4.2s ease-in-out infinite 1s;
    }
  }

  &--19 {
    top: 71%;
    left: 56%;
    animation: rotateR 15s linear infinite;

    &::before {
      animation: twinkle 9.9s ease-in-out infinite 3.1s;
    }
  }

  &--20 {
    top: 60%;
    left: 47%;
    animation: rotate 12s linear infinite;

    &::before {
      animation: twinkle 4s ease-in-out infinite 4.5s;
    }
  }

  &--21 {
    top: 58%;
    left: 35%;
    animation: rotateR 14s linear infinite;

    &::before {
      animation: twinkle 11.6s ease-in-out infinite 4.5s;
    }
  }

  &--22 {
    top: 75%;
    left: 28%;
    animation: rotate 8s linear infinite;

    &::before {
      animation: twinkle 9s ease-in-out infinite 3.7s;
    }
  }

  &--23 {
    top: 83%;
    left: 20%;
    animation: rotateR 15s linear infinite;

    &::before {
      animation: twinkle 10s ease-in-out infinite 4s;
    }
  }

  &--24 {
    top: 80%;
    left: 9%;
    animation: rotate 6s linear infinite;

    &::before {
      animation: twinkle 11s ease-in-out infinite 1s;
    }
  }

  &--25 {
    top: 67%;
    left: 11%;
    animation: rotateR 8s linear infinite;

    &::before {
      animation: twinkle 7s ease-in-out infinite 4s;
    }
  }

  &--26 {
    top: 59%;
    left: 11%;
    animation: rotate 7.5s linear infinite;

    &::before {
      animation: twinkle 4s ease-in-out infinite 3s;
    }
  }

  &--27 {
    top: 63%;
    left: 4%;
    animation: rotateR 9s linear infinite;

    &::before {
      animation: twinkle 5s ease-in-out infinite 7s;
    }
  }

  &--28 {
    top: 54%;
    left: 16%;
    animation: rotate 7s linear infinite;

    &::before {
      animation: twinkle 8s ease-in-out infinite 1.5s;
    }
  }

  &--29 {
    top: 39%;
    left: 21%;
    animation: rotate 6s linear infinite;

    &::before {
      animation: twinkle 7s ease-in-out infinite 2.3s;
    }
  }

  &--30 {
    top: 34%;
    left: 12%;
    animation: rotateR 12s linear infinite;

    &::before {
      animation: twinkle 6s ease-in-out infinite 3.2s;
    }
  }

  &--31 {
    top: 35%;
    left: 57%;
    animation: rotate 14s linear infinite;

    &::before {
      animation: twinkle 10s ease-in-out infinite 2.7s;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes rotateR {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes twinkle {
  0% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }

  50% {
    transform: scale(1);
  }

  90% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}