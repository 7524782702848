@use '../styles/utils';
@use 'sass:math';

$shadow: #002f6c;

.buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 600px;
  margin: auto;
  gap: 14px;
}

.reveal {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 380px;
  aspect-ratio: 380 / 350;
  position: relative;

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 30%;
  }

  &__name {
    font-family: 'Coiny';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.01em;
    color: #fff;
    position: absolute;
    bottom: 0;
    z-index: 5;
    width: 100%;
    text-align: center;
    top: 115%;

    text-shadow:
      3px 0 $shadow, -3px 0 $shadow, 0 3px $shadow, 0 -3px $shadow,
      2px 2px $shadow, -2px -2px $shadow, 2px -2px $shadow, -2px 2px $shadow,
      -1px 6px $shadow, 1px 6px $shadow;

    @media (max-width: 420px) {
      font-size: utils.num-vw(36, 420);
    }
  }

  &__button {
    all: unset;
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__canvas {
    width: math.percentage(math.div(600, 380));
    height: math.percentage(math.div(750, 350));
    top: math.percentage(math.div(-250, 350));
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    //background: url('../assets/placeholders/squishie-canvas-reference.png');
    //background-size: 100% auto;
  }
}
