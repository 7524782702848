@use '../styles/utils';

.root {
  position: relative;

  &[data-uppercase="true"] {
    text-transform: uppercase;
  }

  &[data-icon="back"] {
    &::before {
      content: "";
      width: 8px;
      height: 14px;
      background: url('../assets/components/Button/icon-prev.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -0.15em;
    }
  }

  &[data-icon="next"] {
    &::after {
      content: "";
      width: 8px;
      height: 14px;
      background: url('../assets/components/Button/icon-next.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -0.15em;
    }
  }

  &[data-icon="timer"] {
    &::before {
      content: "";
      width: 14px;
      height: 20px;
      background: url('../assets/components/Button/icon-timer.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -0.15em;
    }
  }

  &[data-icon="skip-left"] {
    border-radius: 30px;
    width: 56px;
    height: 50px;
    padding: 0;

    &::before {
      content: "";
      width: 18px;
      height: 16px;
      background: url('../assets/components/Button/icon-skip.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
    }
  }

  &[data-icon="skip-right"] {
    border-radius: 30px;
    width: 56px;
    height: 50px;
    padding: 0;

    &::after {
      content: "";
      width: 18px;
      height: 16px;
      background: url('../assets/components/Button/icon-skip.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
      transform: rotate(180deg);
    }
  }

  &[data-icon="family"] {
    &::before {
      content: "";
      width: 22px;
      height: 20px;
      background: url('../assets/components/Button/icon-family.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -0.15em;
    }
  }

  &[data-icon="plus"] {
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: url('../assets/components/Button/icon-plus.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -0.15em;
    }
  }

  &[data-icon="map"] {
    &::before {
      content: "";
      width: 20px;
      height: 18px;
      background: url('../assets/components/Button/icon-map.png') center center no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -0.15em;
    }
  }

  &[data-icon="next-ml"] {
    @include utils.bp-any(md, lg) {
      &::after {
        content: "";
        width: 8px;
        height: 14px;
        background: url('../assets/components/Button/icon-next.png') center center no-repeat;
        background-size: 100% 100%;
        position: relative;
        top: -0.15em;
      }
    }
  }
}

.href {
  position: relative;
}

.link {
  display: block;
  width: fit-content;
  text-decoration: unset;
}

.button,
.submit,
.link,
.href {
  border: 4px white solid;
  background: utils.$orange;
  border-radius: 20px;
  padding: 18px 24px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  cursor: pointer;
  transition: border 0.1s ease-out, transform 0.1s ease-out;

  & > span {
    font-family: 'Coiny';
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: white;
  }

  &:hover {
    background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
    border: 4px solid #002F6C;
    transform: scale(1.05);
  }

  &:active {
    background: #002F6C;
    transform: scale(1);
  }

}
