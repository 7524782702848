@use '../styles/utils';

.root {
  position: relative;
  aspect-ratio: 800 / 456;
  width: 100%;
}

.container {
  position: relative;
  border-radius: 30px;
  background-color: utils.$blue;
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: 2px solid utils.$blue;
  box-shadow: 0px 5px 0px utils.$blue;
  background-color: utils.$grey;
  display: block;
}

.button {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

