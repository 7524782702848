@use "../styles/utils";

.header {
  z-index: 8000;
  width: 100%;
  height: 0;
  top: 0;

  @include utils.bp-any(lg) {
    position: sticky;
  }

  @include utils.bp-any(sm, md) {
    &[data-open="true"] {
      position: fixed;
      z-index: 9999;
    }

    &[data-open="false"] {
      position: sticky;
    }
  }
}

.logo {
  position: absolute;
  width: 160px;
  height: 80px;
  top: 14px;
  z-index: 100;

  @include utils.bp-any(lg) {
    top: 14px;
    left: 7px;
    z-index: 8001;
  }

  @include utils.bp-any(sm, md) {
    top: 7px;
    left: calc(50% - 80px);

    &[data-show="true"] {
      position: fixed;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.nav {
  position: relative;
}
