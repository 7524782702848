@use '../styles/utils';

.root {
  background-color: rgba(0, 29, 67, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}