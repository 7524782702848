@use '../styles/utils';

.root {
  z-index: 8500;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background {
  @include utils.bp-any(sm) {
    padding: 50px utils.$pageMarginMobile;
  }

  @include utils.bp-any(md, lg) {
    padding: 50px utils.$pageMarginDesktop;
  }
}

.squishie {
  margin-top: 30px;
  position: relative;
  animation: animationGrowUp 0.4s ease-out;
}

.card {
  position: relative;
  perspective: 1000px;
}

.close {
  cursor: pointer;
  position: absolute;
  border: 0;
  background-color: transparent;

  &--content,
  &--window {
    display: none;
    position: absolute;
  }

  @include utils.bp-any(sm, md) {
    &--window {
      display: block;
      right: 20px;
      top: 20px;
    }
  }

  @include utils.bp-any(lg) {
    &--content {
      display: block;
      right: -30px;
      top: -30px;
    }
  }
}

.container {
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;

  @include utils.bp-any(sm) {
    width: 320px;
    height: 377px;
  }

  @include utils.bp-any(md, lg) {
    width: 382px;
    height: 450px;
  }

  &[data-reverse="false"] {
    animation: flipToFront 0.4s ease-in-out forwards;

    .backSide {
      opacity: 0;
      transition: opacity 0s linear 0.2s;
    }
  }

  &[data-reverse="true"] {
    animation: flipToBack 0.44s ease-in-out forwards;

    .frontSide {
      opacity: 0;
      transition: opacity 0s linear 0.2s;
    }
  }
}

.bgShadow {
  position: absolute;
  background-image: url('../assets/routes/squishie-modal/bg-blue-shadow.png');
  background-size: contain;
  background-repeat: no-repeat;

  @include utils.bp-any(sm) {
    top: -2px;
    left: -16px;
    width: 354px;
    height: 412px;
  }

  @include utils.bp-any(md, lg) {
    top: 0;
    left: -20px;
    width: 422px;
    height: 491px;
  }
}

.frontSide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url('../assets/routes/squishie-modal/bg-blue-white.png');
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateZ(1px);

  @include utils.bp-any(sm) {
    padding: 0 32px;
  }

  @include utils.bp-any(md, lg) {
    padding: 0 40px;
  }

  &__name {
    display: flex;
    align-items: flex-end;
    font-family: 'Coiny';
    color: #FFF;
    line-height: 1em;
    text-shadow: 0px 2px 0px rgba(0, 131, 202, 0.5);

    @include utils.bp-any(sm) {
      font-size: 25px;
      height: 120px;
      padding-bottom: 18px;
      text-transform: uppercase;
    }

    @include utils.bp-any(md, lg) {
      font-size: 30px;
      height: 142px;
      padding-bottom: 20px;
    }
  }

  &__info {
    display: flex;
    gap: 10px;

    @include utils.bp-any(sm) {
      padding-top: 15px;
    }

    @include utils.bp-any(md, lg) {
      padding-top: 18px;
    }
  }

  &__meta {
    @include utils.no-margin-overflow();

    @include utils.bp-any(sm) {
      font-size: 14px;

      & > div {
        margin: 5px 0;
      }
    }

    @include utils.bp-any(md, lg) {
      & > div {
        margin: 6px 0;
      }
    }
  }

  &__visual {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 80px;
    height: 100px;

    @include utils.bp-any(sm) {
      width: 67.05px;
      height: 88.84px;
    }

    &::after {
      content: "";
      position: absolute;
      width: inherit;
      height: inherit;
      border-radius: 1000px;
    }

    &[data-type="locked"] {
      &::after {
        background: radial-gradient(46.75% 34.83% at 52% 53.1%, #0083ca80 0%, #0083ca00 100%), #1A3768;
      }
    }

    &[data-type="common"] {
      &::after {
        background: radial-gradient(46.75% 34.83% at 52% 53.1%, #ffee7580 0%, #ffee7500 100%), utils.$orange;
      }
    }

    &[data-type="rare"] {
      &::after {
        background: radial-gradient(46.75% 34.83% at 52% 53.1%, #FFEE75 0%, #ffee7500 100%), #F6B100;
      }
    }
  }

  &__image {
    display: block;
    width: inherit;
    height: auto;
    z-index: 1;
  }
}

.backSide {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0px) rotateY(180deg);
  width: 100%;
  height: 100%;
}

.leftSide {
  & > * {
    margin: 6px 0;
  }
}

.description {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4em;
  display: -webkit-box;

  @include utils.bp-any(sm) {
    font-size: 11.73px;
  }
}

.backSide__top {
  aspect-ratio: 2;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: absolute;
  font-family: 'Coiny', sans-serif;
  z-index: 2;

  > span {
    padding-top: 5px;
    width: 50%;
    color: white;
    text-shadow: 0px 3px 0px rgba(255, 103, 32, 0.3);
  }

  @include utils.bp-any(sm) {
    height: 41px;
    font-size: 24px;
    top: 20px;
    right: 12px;
  }

  @include utils.bp-any(md, lg) {
    height: 50px;
    font-size: 30px;
    top: 25px;
    right: 15px;
  }
}

[data-rare="false"] .backSide__top {
  background-color: rgba(255, 255, 255, 0.5);
}

[data-rare="true"] .backSide__top {
  background-color: rgba(255, 103, 32, 0.3);
}

.icon {
  height: 100%;
  aspect-ratio: 1;
  display: block;
  background-color: #FFF;
  border-radius: 50%;
}

.backSide__canvas {
  position: absolute;
  left: 50%;
  pointer-events: none;
  z-index: 5;

  @include utils.bp-any(md, lg) {
    width: 600px;
    height: 750px;
    margin-left: -300px;
    top: -290px;
  }

  @include utils.bp-any(sm) {
    width: 502px;
    height: 628px;
    margin-left: -251px;
    top: -218px;
  }
}

.backSide__name {
  width: 100%;
  text-align: center;
  font-size: 30px;
  line-height: 33px;
  text-transform: uppercase;
  position: absolute;
  bottom: 25px;
  margin: 0;

  @include utils.bp-any(sm) {
    font-size: 25.13px;
    bottom: 22px;
  }
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.flip {
  appearance: none;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #FFF;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    background: url('../assets/routes/squishie-modal/icon-flip.png') no-repeat center center;
    background-size: contain;
    width: 24px;
    aspect-ratio: 1;
  }
}

@keyframes animationGrowUp {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes flipToBack {
  0% {
    transform: scaleY(1) rotateY(0deg);
  }
  50% {
    transform: scaleY(1.1) rotateY(90deg);
  }
  100% {
    transform: scaleY(1) rotateY(180deg);
  }
}

@keyframes flipToFront {
  0% {
    transform: scaleY(1) rotateY(180deg);
  }
  50% {
    transform: scaleY(1.1) rotateY(90deg);
  }
  100% {
    transform: scaleY(1) rotateY(0deg);
  }
}