@use "../styles/utils";

$desktop: '@media (min-width: 1200px)';

.root {
  position: relative;
  font-family: 'Roboto', sans-serif;
  z-index: 8002;
  background-color: white;
}

.topWhite {
  position: relative;
  height: 66px;
  overflow: hidden;
  max-width: 90%;
  margin: auto;
  display: flex;
}

.bottomBlue {
  position: static;
  background-color: #00b5e2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonMenu {
  display: none;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding-top: 5px;

  &::before {
    content: "";
    display: block;
    border: solid black;
    height: 8px;
    border-width: 2px 0px;
    width: 80%;
  }

  &::after {
    content: "";
    display: block;
    border: 0px solid black;
    height: 7px;
    border-bottom-width: 2px;
    width: 80%;
  }

  > span {
    padding-top: 3px;
    order: 3;
  }
}

.iconMain {
  display: flex;
  align-items: center;

  > img {
    width: 68px;
    height: auto;
  }
}

.formSearch {
  width: 100%;
  margin: 8px 0;
  display: flex;
  border: 4px;
  margin-left: 60px;
  background-color: white;

  > input {
    font-size: 16px;
    width: 315px;
    border-radius: 4px 0 0 4px;
    padding: 6px 12px;
    border: 1px solid #696158;
  }
}

.buttonClose {
  display: none;
  background-color: transparent;
  border: 0;
  margin-right: 10px;
  cursor: pointer;

  > svg path {
    stroke: utils.$blue;
  }
}

.submitSearch {
  cursor: pointer;
  background-color: utils.$blue;
  border: 0;
  width: 48px;
  border-radius: 0 4px 4px 0;
  transition: filter 0.25s ease-out;

  > svg {
    width: 18px;
    fill: white;
  }

  &:hover {
    filter: brightness(0.85);
  }
}

.buttonSearch {
  display: none;
}

.iconSupport {
  width: 78px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  color: utils.$blue;
  text-decoration: none;
  font-size: 12px;
  gap: 4px;
  
  &,
  > * {
    transition: all 0.25s ease-out;
  }

  > svg {
    width: 22px;
    height: 30px;
    stroke: utils.$blue;
  }

  &:hover {
    background-color: utils.$blue;
    color: white;

    > svg {
      stroke: white;
    }
  }
}

.buttonLink {
  color: utils.$blue;
  text-decoration: none;
  padding: 12px 16px;
  transition: all 0.25s ease-out;
  font-size: 14px;

  &:hover {
    background-color: utils.$blue;
    color: white;
  }

  &[data-name="support"] {
    @include utils.bp-any(md, lg) {
      display: none;
    }
  }
}

@include utils.bp-any($desktop) {
  .topWhite {
    max-width: 1080px;
  }
}

@include utils.bp-any(sm) {
  .topWhite {
    height: 47px;
    justify-content: space-between;
    align-items: center;
  }

  .bottomBlue {
    position: fixed;
    z-index: 8001;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    background-color: white;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;

    &[data-show="true"] {
      min-height: 100vh;
      max-height: 200vh;
    }
  }

  .buttonMenu {
    display: flex;
  }

  .iconMain {
    width: 41px;

    @include utils.bp-any(sm, md) {
      position: absolute;
      left: 55px;
      height: 100%;
    }

    > img {
      width: 100%;
      height: auto;
    }
  }

  .formSearch {
    position: absolute;
    margin: 0;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;

    > input {
      width: 100%;
    }

    &[data-show="true"] {
      max-height: 100px;
      padding: 8px 16px;
    }
  }

  .buttonClose {
    display: block;
  }

  .buttonSearch {
    display: block;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    width: 48px;
  
    > svg {
      width: 18px;
      fill: utils.$blue;
    }
  }

  .iconSupport {
    display: none;
  }

  .buttonLink {
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;

    &:nth-last-child(2) {
      margin-bottom: 20px;
    }
  }
}
