.root {
  text-align: center;
  max-width: 600px;
  min-height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.title {
  margin-top: 0;
  margin-bottom: 4px;
  font-family: 'Coiny';
  font-size: 30px;
  line-height: 1.1em;
  color: #002F6C;
  font-weight: 400;
}

.description {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8em;
  color: #002F6C;
}