@use '../styles/utils';

.root {
  background: rgba(0, 191, 220, 0.1);
  background: linear-gradient(270deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%);
  text-align: center;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include utils.bp-any(sm) {
    padding: 40px 20px;
    border-radius: 40px;
  }

  @include utils.bp-any(md, lg) {
    padding: 50px 25px;
    border-radius: 999px;
  }
}

.inner {
  @include utils.no-margin-overflow();
  color: #002F6C;
}

.headline {
  font-size: 30px;
  font-family: 'Coiny', sans-serif;
  margin-top: 0;
  margin-bottom: 0.2em;
  line-height: 1.1em;
}

.copy {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 14px;
  line-height: 1.8em;
  max-width: 600px;
  margin: 0 auto;
}