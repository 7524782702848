@use "../styles/utils";

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background {
  @include utils.bp-any(sm) {
    padding: 50px utils.$pageMarginMobile;
  }

  @include utils.bp-any(md, lg) {
    padding: 50px utils.$pageMarginDesktop;
  }
}

.content {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.close {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  cursor: pointer;

  @include utils.bp-any(sm, md) {
    &--overlay {
      display: block;
      top: 20px;
      right: 20px;
    }
  }

  @include utils.bp-any(lg) {
    &--content {
      display: block;
      top: -20px;
      right: -20px;
    }
  }
}