@use '../styles/utils';

$listBp: '@media (max-width: 580px)';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.thumbnail {
  @include utils.bp-any(md, lg) {
    flex: 200px 0 1;
  }

  @include utils.bp-any(sm) {
    flex: calc(33.333% - 20px) 0 1;
  }

  @include utils.bp-any($listBp) {
    flex: calc(50% - 20px) 0 1;
  }
}

