@use "../styles/utils";

$short: "@media screen and (max-height: 499px)";

.root {
  //
}

.ulLinks {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: center;

  @include utils.bp-any(sm, md) {
    flex-flow: column nowrap;
  }
}

.liLink {
  @include utils.bp-any(lg) {
    padding: 0 6px;
  }

  @include utils.bp-any(sm, md) {
    padding: 0 30px 30px 30px;
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include utils.bp-any($short) {
      padding-bottom: 15px;
    }
  }
}

.burgerButton {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  width: 55px;
  height: 55px;
  background: #FFFFFF;
  border: 2px solid #002F6C;
  box-shadow: 0px 5px 0px #002F6C, inset 0px -5px 0px rgba(0, 131, 202, 0.2);
  border-radius: 19px;
  cursor: pointer;
  top: 12px;
  right: 10px;
  z-index: 100;

  @include utils.bp-any(lg) {
    display: none;
  }
}

.line {
  &__top,
  &__middle,
  &__bottom {
    display: block;
    background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
    border-radius: 2px;
    width: 24px;
    height: 4px;
    transition: all 0.3s ease-out;
    position: absolute;
  }

  &__top {
    top: 14px;
    left: calc(50% - 12px);
  }

  &__middle {
    top: 23px;
    left: calc(50% - 12px);

  }

  &__bottom {
    top: 32px;
    left: calc(50% - 12px);
  }
}

.burgerButton[data-show="true"] {
  .line__top {
    transform: translate(-3px, 9px) rotate(45deg);
    width: 32px;
  }

  .line__middle {
    transform: translateX(-14px);
    opacity: 0;
  }

  .line__bottom {
    transform: translate(-3px, -9px) rotate(-45deg);
    width: 32px;
  }
}

.navContent {
  @include utils.bp-any(sm, md) {
    display: none;
    position: absolute;

    &[data-show="true"] {
      display: block;
      background: #ff6620 url("../assets/components/Nav/bg-mobile.png") center top;
      width: 100%;
      height: 100vh;
      opacity: 1;
      background-blend-mode: multiply;
    }
  }

  @include utils.bp-any(lg) {
    position: relative;
    top: 20px;
  }
}

.logo {
  @include utils.bp-any(sm, md) {
    width: 160px;
    height: 80px;
    margin: 7px auto 80px auto;
    display: block;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  @include utils.bp-any($short) {
    margin-bottom: 10px;
  }

  @include utils.bp-any(lg) {
    display: none;
  }
}
