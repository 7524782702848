// Utilities – Utilities and helper classes with ability to override anything which goes before in the triangle, e.g. hide helper class
// This is NOT to be confused with _utils, which imports tools and settings
@use 'utils';

.no-margin-overflow {
  @include utils.no-margin-overflow();
}

.invisible {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}