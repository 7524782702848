.root {
  box-sizing: border-box;
}

.inner {
  position: relative;
  min-height: 50px;
  background-color: #FFF;
  border: solid 2px #002F6C;
  color: #002F6C;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 120px;
  font-family: 'Coiny', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  box-shadow: inset 0 -5px #cbe6f3;

  &::after {
    content: "";
    display: block;
    width: 30px;
    height: 16px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -16px;
    background: url(../assets/components/Tooltip/arrow.png);
    background-size: 100%;
  }
}