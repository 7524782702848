@use '../styles/utils';

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  z-index: 20;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include utils.blue-bg();
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.shapeLoader {
  max-width: 360px;
  width: 100%;
  padding: 41px 50px;
  border-radius: 65px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  gap: 23px;

  @include utils.bp-any(sm) {
    max-width: 300px;
    padding: 31px 44px;
    gap: 18px;
  }
}

.boxBlue {
  width: 36px;
  height: 36px;
  background-color: utils.$box-blue-color;
  mask-image: url('../assets/components/GlobalCreateTimer/icon-box.png');
  mask-repeat: no-repeat;
  mask-size: contain;
}

.title {
  font-family: 'Coiny';
  font-size: 22px;
  line-height: 24px;
  color: utils.$blue;
  white-space: pre-wrap;

  @include utils.bp-any(sm) {
    font-size: 18px;
    line-height: 20px;
  }
}

.shapeButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.welcome {
  display: block;
  aspect-ratio: 342 / 154;
  max-width: 342px;
  width: 100%;
}