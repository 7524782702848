@use "../styles/utils";

.root {
  display: block;
}

.copyright {
  display: flow-root;
  border-top: 8px solid #fff;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Roboto', sans-serif;
  background-color: #f7f7f7;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 94%;
    margin-left: 3%;
    margin-right: 3%;

    @media (max-width: 575px) {
      flex-direction: column;
    }

    @media (min-width: 576px) {
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    @media (min-width: 768px) {
      max-width: 94%;
      margin-left: 3%;
      margin-right: 3%;
    }

    @media (min-width: 992px) {
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    @media (min-width: 1200px) {
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 1600px) {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__logo {
    display: block;
    width: 56px;
    height: 34px;
    margin-top: 24px;
    margin-bottom: 24px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__copy {
    font-size: 12px;
    padding-left: 24px;
    margin-bottom: 24px;

    @media (min-width: 576px) {
      margin-top: 24px;
    }
  }
}
