@use '../styles/utils';

$listBp: '@media (max-width: 580px)';

.root {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;

  @include utils.bp-any(md, lg) {
    row-gap: 40px;
  }

  @include utils.bp-any(sm) {
    row-gap: 26px;
  }
}

.thumbnail {
  @include utils.bp-any(md, lg) {
    flex: 200px 0 0;
  };

  @include utils.bp-any(sm) {
    flex: calc(33.333% - 14px) 0 1;
  }

  @include utils.bp-any($listBp) {
    flex: calc(50% - 10px) 0 1;
  }
}

.root[data-is-unlocked="true"] {
  //
}

.root[data-is-unlocked="false"] {
  //
}