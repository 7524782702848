@use '../styles/utils';

$desktop: '@media (min-width: 391px)';
$mobile: '@media (max-width: 390px)';

.root {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: inherit;
  width: 100%;
  z-index: 5000;
  color: #FFF;

  @include utils.bp-any($desktop) {
    max-width: 350px;
  }

  @include utils.bp-any($mobile) {
    overflow: hidden;
  }

  &--sticky {
    position: sticky;
    bottom: 0px;

    @include utils.bp-any(sm) {
      margin: 0 auto;
      left: 0;
      transform: unset;
    }

    .close {
      position: absolute;
    }
  }
}

.background {
  position: relative;
  height: 100%;
  background-image: url('../assets/components/GlobalCreateTimer/timerOnMapBg.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -50;

  @include utils.bp-any($desktop) {
    width: 100%;
  }

  @include utils.bp-any($mobile) {
    width: 110%;
    left: -5%;
  }
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  padding-top: 12px;
  padding-bottom: 5px;
}

.iconBox {
  width: 40px;
  height: 40px;
  animation: rotate 2s linear;
  animation-iteration-count: infinite;
}

.timeDone {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.clock {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  color: #FFFFFF;
}

.barProgress {
  padding-bottom: 14px;
}

.close {
  all: unset;
  position: fixed;
  cursor: pointer;
  right: calc(50% - 150px);
  top: calc(50% - 78px);
}

.mold {
  width: 124px;
  height: 124px;
  margin-top: 17px;
  margin-bottom: 6px;
}

.button {
  background: #FFFFFF;
  border: 2px solid #002F6C;
  box-shadow: 0px 5px 0px #002F6C, inset 0px -7px 0px rgba(0, 131, 202, 0.2);
  border-radius: 20px;
  text-align: center;
  padding: 13px 20px 13px 22px;
  margin-bottom: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.1s ease-out;
  
  &:hover {
    background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
    transform: scale(1.05);

    > * {
      background: #FFF;
      background-clip: text;
      text-fill-color: transparent;
    }

    > svg > path {
      fill: #FFF;
    }
  }

  &:active {
    transform: scale(1);
  }
}

.button__text {
  font-family: 'Coiny';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
