@use '../styles/utils';
@use 'sass:math';

.root {
  position: relative;

  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: percentage(math.div(240, 200));
    height: percentage(math.div(270, 235));
    left: percentage(math.div(-20, 200));
    top: percentage(math.div(5, 235));
    background: url(../assets/components/SquishieThumbnail/card-shadow.png) no-repeat center center;
    background-size: 100% 100%;
  }
}

.unlocked {
  width: percentage(math.div(38, 200));
  height: percentage(math.div(38, 235));
  position: absolute;
  right: percentage(math.div(-15, 200));
  bottom: percentage(math.div(-10, 235));
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0px 20px 20px rgba(0, 47, 108, 0.3);

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

