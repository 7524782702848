@use '../styles/utils';

.inner {
  position: relative;
  max-width: 800px;
  width: 100%;
  text-align: center;
  animation: animationGrowUp 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  color: #FFF;
}

.biomeCarousel {
  position: relative;
  border-radius: 162.5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 0;
}

.nav {
  &__prev, &__next {
    width: 70px;
    height: 70px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    cursor: pointer;
    top: calc(50% - 70px / 2);

    &[disabled] {
      opacity: 0.3;
      cursor: unset;
    }
  }

  &__prev {
    left: 125px;
    z-index: 100;
    transition: all 0.2s;

    @include utils.bp-any(sm, md) {
      background-image: url('../assets/components/BiomeModal/icon-arrow-white-left.png');
    }

    @include utils.bp-any(lg) {
      background-image: url('../assets/components/BiomeModal/icon-arrow-blue-left.png');
    }
  }

  &__next {
    right: 125px;
    z-index: 100;
    transition: all 0.2s;

    @include utils.bp-any(sm, md) {
      background-image: url('../assets/components/BiomeModal/icon-arrow-white-right.png');
    }

    @include utils.bp-any(lg) {
      background-image: url('../assets/components/BiomeModal/icon-arrow-blue-right.png');
    }
  }

  &__swiperSlide {
    position: relative;
  }

  &__swiper {
    border-radius: 200px;
  }

  &__swiperPaginationHorizontal {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15px !important;
  }

  &__swiperBullet {
    display: block;
    height: 5px;
    width: 5px;
    margin: 0 3px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
  }

  &__swiperBulletActive {
    background-color: #fff;
  }
}


.toolTipBiome {
  position: absolute;
  top: -24px;
  left: calc(50% - 150px);
  z-index: 1;
  width: 300px;
  opacity: 1;
  transition: all 0.1s ease-out;

  font-family: 'Coiny';
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #002F6C;
}

.toolTipBiomeHide {
  opacity: 0;
  top: -14px;
}

.textContent {
  max-width: 600px;
  margin: 0 auto;
}

.biomeName {
  font-family: 'Coiny', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 6px;
}

.biomeDescription {
  font-family: 'M PLUS Rounded 1c';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.closeIcon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: fixed;

  &--content,
  &--window {
    display: none;
  }

  @include utils.bp-any(lg) {
    &--content {
      display: block;
      top: -8px;
      right: -10px;
    }
  }

  @include utils.bp-any(sm, md) {
    &--window {
      display: block;
      top: 20px;
      right: 20px;
    }
  }
}

.wrapperImage {
  background: transparent;
  border-radius: 162.5px;
  overflow: hidden;
}

.responsiveImage {
  position: relative;
  aspect-ratio: 324 / 220;
  max-width: 324px;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 35px;
  transition: all 0.2s;

  &::before {
    content: "";
    position: absolute;
    left: 2%;
    bottom: 38px;
    width: 96%;
    aspect-ratio: 2.14;
    z-index: -1;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);

    @include utils.bp-any(sm) {
      bottom: 8px;
      left: 8%;
      width: 84%;
    }
  }
}

.imageHabitat {
  width: 100%;
  height: auto;
}

@include utils.bp-any(md) {
  .biomeCarousel {
    margin: 0 60px;
  }
}

@include utils.bp-any(sm) {
  .responsiveImage {
    padding: 26px 20px 0;
  }

  .biomeCarousel {
    margin: 0 65px;
  }

  .biomeName {
  margin-top: 20px;
  }

  .toolTipBiome {
    top: -35px;
  }
}

@include utils.bp-any(sm, md) {
  .nav {
    &__prev {
      left: calc(0% - 64px);
    }
  
    &__next {
      right: calc(0% - 64px);
    }
  }

  .textContent {
    padding: 0 20px;
  }
}

@keyframes animationGrowUp {
  from {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}
