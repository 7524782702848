@use '../styles/utils';

.root {
  display: flow-root;

  @include utils.bp-any(sm, md) {
    margin-top: 30px;
  }

  @include utils.bp-any(lg) {
    margin-top: 50px;
  }
}

.container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.video {
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: center;
}