@use '../styles/utils';

.root {
  position: relative;
}

.button {
  margin: 0 auto;
  min-width: 230px;
}
