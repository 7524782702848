// Settings - Variable configurations for things like colors, fonts, sizes, etc...
// May not be highly useful for this style of project

$blue: #002F6C;
$orange: #FF6720;
$grey: #D9D9D9;
$box-blue-bg: #cfeaf5;
$box-blue-border: #e5f4fa;
$box-blue-underline: #0083ca80;
$box-blue-color: #0083CA;
$headerHeightDesktop: 94px;
$headerHeightMobile: 82px;
$pageMarginDesktop: 30px;
$pageMarginMobile: 20px;
$headerElmersHeightDesktop: 106px;
$headerElmersHeightMobile: 47px;
$footerScrollButtonHeight: 30px;