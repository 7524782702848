@use '../styles/utils';

.root {
  @include utils.bp-any(sm) {
    height: calc(var(--app-height) - #{utils.$headerElmersHeightMobile});
  }

  @include utils.bp-any(md, lg) {
    height: calc(var(--app-height) - #{utils.$headerElmersHeightDesktop});
  }
}

.footerButton {
  all: unset;
  position: absolute;
  width: 100%;
  left: 0;
  height: utils.$footerScrollButtonHeight;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  z-index: 5;

  &::after {
    content: "";
    background: url('../assets/routes/home/icon-arrow-down.svg') no-repeat center center;
    width: 24px;
    height: 12px;
    display: block;
  }
}
