@use '../styles/utils';

.root {
  position: relative;
  @include utils.blue-bg();
}

.page {
  position: relative;
}

.content {
  background-color: #FFF;
  position: relative;
  display: flow-root;

  &::after {
    content: "";
    display: block;
    height: 15px;
    @include utils.animated-wave();
    position: absolute;
    bottom: -15px;
    width: 100%;
  }
}

.activities {
  &__header {
    height: 130px;
    text-align: center;
    margin: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 10px;
    width: 100%;
    color: #FFFFFF;
    font-family: 'Coiny', sans-serif;
    font-weight: 400;
    text-shadow: 0px 2px 0px rgba(0, 131, 202, 0.5);
    font-size: 30px;
  }

  &__content {
    background: linear-gradient(270deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%), #FFFFFF;
    display: flow-root;
    position: relative;
    overflow: hidden;
  }

  &__list {
    @include utils.bp-any(sm) {
      margin-top: 40px;
      margin-bottom: 80px;
    }

    @include utils.bp-any(md, lg) {
      margin-top: 60px;
      margin-bottom: 150px;
    }
  }
}