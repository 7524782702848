@use "../styles/utils";

.root {
  @include utils.bp-any(sm, md) {
    margin-top: 30px;
  }

  @include utils.bp-any(lg) {
    margin-top: 50px;
  }
}

.topRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxOrange {
  @include utils.bp-any(sm) {
    max-width: 116px;
  }

  @include utils.bp-any(md, lg) {
    max-width: 164px;
  }
}

.boxBlue {
  position: relative;

  @include utils.bp-any(sm) {
    width: 156px;
    height: 120px;
    margin-left: 26px;
  }

  @include utils.bp-any(md, lg) {
    width: 216px;
    height: 170px;
    margin-left: 40px;
  }
  
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    margin: auto;
    border-bottom-left-radius: 20%;
    background-color: utils.$box-blue-border;

    @include utils.bp-any(sm) {
      transform: scale(1, 0.5) rotate(45deg);
      width: 50px;
      height: 50px;
      top: calc(50% - 25px);
      left: -15px;
    }

    @include utils.bp-any(md, lg) {
      transform: scale(1, 0.5) rotate(45deg);
      width: 50px;
      height: 50px;
      top: calc(50% - 25px);
      left: -20px;
    }
  }

  &::after {
    content: "";
    border-bottom: 2px solid utils.$box-blue-underline;
    width: 100%;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;

    @include utils.bp-any(sm) {
      max-width: 100px;
      bottom: 20%;
    }

    @include utils.bp-any(md, lg) {
      max-width: 135px;
      bottom: 20%;
    }
  }
}

.boxBlueInner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: utils.$box-blue-bg;

  @include utils.bp-any(sm) {
    border-radius: 14px;
    border: 4px solid utils.$box-blue-border;
  }

  @include utils.bp-any(md, lg) {
    border-radius: 20px;
    border: 8px solid utils.$box-blue-border;
  }
}

.label {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}

.numberField {
  -moz-appearance: textfield;
  text-align: center;
  font-family: 'Coiny';
  color: utils.$box-blue-color;
  outline: none;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: block;

  @include utils.bp-any(sm) {
    font-size: 71px;
  }

  @include utils.bp-any(md, lg) {
    font-size: 100px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  &::placeholder {
    color: #aedfef;
  }
}

.bottomRow {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 360px;

  @include utils.bp-any(sm) {
    margin: 30px auto 0;
  }

  @include utils.bp-any(md, lg) {
    margin: 40px auto 0;
  }
}

.btnNext {
  @include utils.bp-any(md, lg) {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.5;

    &:hover {
      border: 4px white solid;
      background: utils.$orange;
      transform: scale(1);
    }
  }
}

.btnBack {
  @include utils.bp-any(md, lg) {
    width: 100%;
  }
}

.error {
  margin: 20px auto 0 auto;
  min-height: 75px;
  max-width: 400px;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: utils.$orange;
}
