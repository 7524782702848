@use '../styles/utils';

.root {
  width: 100%;

  @include utils.bp-any(sm) {
    margin-left: utils.$pageMarginMobile;
    margin-right: utils.$pageMarginMobile;
  }

  @include utils.bp-any(md, lg) {
    margin-left: utils.$pageMarginDesktop;
    margin-right: utils.$pageMarginDesktop;
  }
}