@use '../styles/utils';

.root {
  text-align: center;
  position: relative;
  overflow: hidden;

  @include utils.bp-any(sm, md) {
    margin-top: utils.$headerHeightMobile * -1;
  }

  @include utils.bp-any(lg) {
    margin-top: utils.$headerHeightDesktop * -1;
  }
}

.inner {
  @include utils.bp-any(sm) {
    margin-top: #{30 + utils.$headerHeightMobile};
  }

  @include utils.bp-any(md) {
    margin-top: #{30 + utils.$headerHeightMobile};
  }

  @include utils.bp-any(lg) {
    margin-top: #{50 + utils.$headerHeightDesktop};
  }
}

.pages {
  @include utils.bp-any(sm) {
    margin-bottom: 95px;
  }

  @include utils.bp-any(md, lg) {
    margin-bottom: 80px;
    min-height: 565px;
  }
}

.activities {
  display: flow-root;

  &__list {
    @include utils.bp-any(sm) {
      margin-top: 40px;
      margin-bottom: 80px;
    }

    @include utils.bp-any(md, lg) {
      margin-top: 60px;
      margin-bottom: 150px;
    }
  }
}
