@use '../styles/utils';

.root {
  position: relative;
}

.container {
  position: relative;
  width: 400px;
  margin: 0 auto 20px;
  background-image: url('../assets/components/CircularCountDown/bg-circle-blue.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.progressBarBlue {
  position: absolute;
  z-index: 1;
  display: block;

  circle {
    stroke-width: 20px;
    stroke-linecap: round;
    fill: transparent;
    stroke: #c5ebf1;
  }
}

.progressBarOrange {
  position: relative;
  z-index: 2;
  display: block;

  circle {
    stroke-width: 20px;
    stroke-linecap: round;
    fill: transparent;
    stroke: utils.$orange;
    opacity: 0;
  }
}

.root[data-ready="true"] .progressBarOrange circle {
  opacity: 1;
}

.progressBarCircles {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
  list-style-type: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  > li {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: utils.$orange;
    border-radius: 50%;

    opacity: 0;
  }
}

.progressBarInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.boxOrange {
  margin-top: 40px;
  max-width: 212px;
}

.countDown {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  margin: 0;
  color: #002F6C;
}

.label {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  margin: -10px 0 0;
  color: #002F6C;
}

@include utils.bp-any(sm) {
  .container {
    width: 320px;
  }

  .boxOrange {
    margin-top: 25px;
    width: 184px;
  }

  .countDown {
    margin-top: -10px;
  }
}
