.headerElmers {
  flex-grow: 0;
  flex-shrink: 0;
}

.fold {
  display: flex;
  flex-direction: column;
  min-height: var(--app-height);
}

.content {
  position: relative;
  flex-grow: 1;
}

.outlet {
  position: relative;
}