// Trumps – Helper or utility rules that tweak Objects or Components by adjusting and override existing rules.

// Extend background of Collect page to prevent global timer from appearing below outlet background
#squishtopia-root {
  .app-root.path--collect {
    .app-content {
      background-color: rgba(0, 131, 202, 0.1);
    }
  }
}

body[data-osano="false"] {
  .osano-cm-dialog {
    display: none !important;
  }
}