.root {
  background: #002F6C;
  border-radius: 15px;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  position: relative;
}

.progress {
  &__1 {
    position: absolute;
    background: #FF6720;
    border-radius: 15px;
    width: 20%;
    height: 100%;
    z-index: 0;
  }
  &__2 {
    position: absolute;
    background: #FF6720;
    border-radius: 15px;
    width: 40%;
    height: 100%;
    z-index: 0;
  }
  &__3 {
    position: absolute;
    background: #FF6720;
    border-radius: 15px;
    width: 60%;
    height: 100%;
    z-index: 0;
  }
  &__4 {
    position: absolute;
    background: #FF6720;
    border-radius: 15px;
    width: 80%;
    height: 100%;
    z-index: 0;
  }
  &__5 {
    position: absolute;
    background: #FF6720;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.textContent {
  position: relative;
  padding: 2px 0;
  z-index: 100;
}

.stage {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #FFFFFF;
}

.title {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 26px;
  color: #FFFFFF;
}
