@use 'sass:math';

.root {
  position: relative;

  a {
    text-decoration: none;
  }

  a:hover {
    color: #002F6C;
    text-decoration: underline;
  }

  &[data-is-unlocked="false"] .image__wrapper::before {
    content: "";
  }
}

.link {
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
}

.image {
  position: relative;
  transition: transform 0.1s ease-out;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }

  &::before {
    content: "";
    display: block;
    width: percentage(math.div(240, 200));
    height: percentage(math.div(240, 200));
    background: url(../assets/components/ActivityThumbnail/shadow.png) no-repeat center center;
    background-size: 100% 100%;
    position: absolute;
    bottom: percentage(math.div(-35, 200));
    left: percentage(math.div(-20, 200));
    pointer-events: none;
  }

  &__wrapper {
    mask-image: url(../assets/components/ActivityThumbnail/mask.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;

    &::before {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background-color: rgba(0, 131, 202, 0.5);
    }
  }

  &__element {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }
}

.locked {
  width: percentage(math.div(30, 200));
  height: percentage(math.div(30, 200));
  background: url(../assets/components/ActivityThumbnail/locked.png) no-repeat center center;
  background-size: 100% 100%;
  position: absolute;
  top: percentage(math.div(-5, 200));
  right: percentage(math.div(-10, 200));
  z-index: 2;
}

.type {
  display: block;
  margin-top: 16px;
  margin-bottom: 10px;
}

.title {
  margin-top: 10px;
  font-size: 12px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  line-height: 1.8em;
  color: #002F6C;
  padding: 0 10px;
}

.description {
  padding: 0 10px;
  font-size: 12px;
  color: #002F6C;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  line-height: 1.8em;
}

