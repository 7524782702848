// Objects – Style rules for elements responsible for layout or structuring.
@use './utils';

.header-offset-fill {
  @include utils.bp-any(sm) {
    margin-top: utils.$headerHeightMobile;
    min-height: calc(100vh - #{utils.$headerHeightMobile} - #{utils.$headerElmersHeightMobile});
  }

  @include utils.bp-any(md) {
    margin-top: utils.$headerHeightMobile;
    min-height: calc(100vh - #{utils.$headerHeightMobile} - #{utils.$headerElmersHeightDesktop});
  }

  @include utils.bp-any(lg) {
    margin-top: utils.$headerHeightDesktop;
    min-height: calc(100vh - #{utils.$headerHeightDesktop} - #{utils.$headerElmersHeightDesktop});
  }
}

.header-offset-fill-padding {
  @include utils.bp-any(sm, md) {
    padding-top: utils.$headerHeightMobile;
    min-height: calc(100vh - #{utils.$headerHeightMobile} - #{utils.$headerElmersHeightMobile});
  }

  @include utils.bp-any(md) {
    padding-top: utils.$headerHeightMobile;
    min-height: calc(100vh - #{utils.$headerHeightMobile} - #{utils.$headerElmersHeightDesktop});
  }

  @include utils.bp-any(lg) {
    padding-top: utils.$headerHeightDesktop;
    min-height: calc(100vh - #{utils.$headerHeightDesktop} - #{utils.$headerElmersHeightDesktop});
  }
}

@keyframes animated-wave {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 410px;
  }
}