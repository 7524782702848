@use '../styles/utils';

.topSection {
  display: flex;
  gap: 12px;
  max-width: 570px;
  padding: 0;
  margin: 0 auto;
}

.blueBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-family: 'Coiny';
  line-height: 33px;
  color: #002F6C;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 160px;

  @include utils.bp-any(sm) {
    font-size: 16px;
    line-height: 18px;
    padding: 16px 22px;

    > span {
      margin-right: -12px;
    }
  }

  @include utils.bp-any(md, lg) {
    font-size: 30px;
    gap: 28px;
    padding: 16px 40px;
  }

  &--mix {
    img {
      display: block;
      width: 100%;
      max-width: 102px;
      aspect-ratio: 102 / 81;
    }
  }

  &--pour {
    img {
      display: block;
      width: 100%;
      max-width: 67px;
      aspect-ratio: 67 / 90;
    }
  }
}

.buttons {
  max-width: 490px;
}

.optionalButtonCopy {
  @include utils.bp-any(sm) {
      display: none;
  }
}