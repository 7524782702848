@use '../styles/utils';

.root {
  display: flow-root;
}

.families {
  @include utils.no-margin-overflow();

  @include utils.bp-any(sm) {
    margin-bottom: 80px;
  }

  @include utils.bp-any(md, lg) {
    margin-bottom: 160px;
  }
}

.family {
  @include utils.no-margin-overflow();

  @include utils.bp-any(md, lg) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  @include utils.bp-any(sm) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__header {
    display: flex;
    align-items: center;

    @include utils.bp-any(md, lg) {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @include utils.bp-any(sm) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin: 0;
    font-family: "M PLUS Rounded 1c";
    font-weight: 800;
    font-size: 24px;
    color: #002F6C;
    display: inline-block;
    margin-right: 16px;
  }

  &__squishies {
    @include utils.bp-any(md, lg) {
      min-height: 235px;
    }
  }
}
