@use '../styles/utils';

.root {
  position: relative;
  background: 
    linear-gradient(270.76deg, #00bfdc1a 0.64%, #0083ca1a 99.54%), #FFFFFF;
  text-align: left;
  border-radius: 20px;
  padding: 30px;
}

.subItem {
  position: absolute;
  width: calc(100% - 60px);
}

.topLabel {
  font-family: 'Coiny';
  font-size: 16px;
  line-height: 18px;
  background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0 0 10px;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: #002F6C;
  margin: 0 0 14px;
  min-height: 32px;
}

.stageNumber {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  width: 32px;
  aspect-ratio: 1;
  background: linear-gradient(180deg, #00BFDC 0%, #0083CA 100%);
  color: white;
  border-radius: 50%;
  text-align: center;
  font-family: 'Coiny';
  font-size: 16px;
  line-height: 18px;
}

.description {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #002F6C;
  min-height: 26px;
}
