@use '../styles/utils';

.root {
  display: flow-root;
}

.squishies {
  &__list {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }

  @include utils.bp-any(sm) {
    margin-bottom: 60px;
  }

  @include utils.bp-any(md, lg) {
    margin-bottom: 80px;
    min-height: 235px;
  }
}

.meta {
  @include utils.bp-any(md, lg) {
    flex: 360px 0 0;
  }

  @include utils.bp-any(sm) {
    margin-bottom: 40px;
  }

  &__header {
    margin-bottom: 10px;
  }

  &__title {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 800;
    font-size: 24px;
    color: #002F6C;
    margin: 0 15px 0 0;
  }

  &__description {
    @include utils.no-margin-overflow();
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8em;
    color: #002F6C;

    p {
      margin: 1em 0;
    }
  }
}

.metaNeighbors {
  @include utils.bp-any(lg) {
    display: flex;
    column-gap: 60px;
  }

  @include utils.bp-any(md) {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }

  @include utils.bp-any(sm) {
    margin-top: 60px;
    margin-bottom: 90px;
  }
}

.neighbors {
  text-align: center;
  background: linear-gradient(270.76deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%);
  border-radius: 999px;

  @include utils.bp-any(sm) {
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include utils.bp-any(md, lg) {
    padding-left: 90px;
    padding-right: 90px;
    justify-content: space-between;
    flex-grow: 1;
    column-gap: 60px;
    height: 200px;
    display: flex;

    @include utils.bp-any('@media (max-width: 1100px)') {
      padding-left: 40px;
      padding-right: 40px;
      column-gap: 20px;
    }
  }

  @include utils.bp-any(md) {
    margin-top: 70px;
    padding-left: 100px;
    padding-right: 60px;
  }

  &__header {
    @include utils.bp-any(md, lg) {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-family: "M PLUS Rounded 1c", sans-serif;
    color: #002F6C;
    font-size: 24px;
    margin: 0;

    @include utils.bp-any(lg) {
      margin-left: 30px;
    }

    @include utils.bp-any(sm) {
      margin-bottom: 60px;
    }
  }

  &__carouselInner {
    position: relative;

    @include utils.bp-any(md, lg) {
      width: 320px;
      height: 200px;
    }
  }

  &__carouselBullets {
    position: absolute;
    bottom: 0 !important;
    width: 100%;
  }

  &__carouselBullet {
    display: inline-block;
    border-radius: 100px;
    width: 5px;
    height: 5px;
    background: rgba(0, 47, 108, 0.3);
    margin: 0 3px;
  }

  &__carouselBulletActive {
    background: #002F6C;
  }

  &__swiper {
    width: 220px;
  }

  &__swiperInner {
    position: relative;
    pointer-events: none;

    @include utils.bp-any(md, lg) {
      top: -35px;
    }
  }

  &__swiperSlide {
    width: 220px;
    height: 230px;
    position: relative;
    transition-property: transform !important; // Intentionally overwriting the `creative-effect` CSS

    &::before {
      content: "";
      position: absolute;
      left: 2%;
      bottom: 18px;
      width: 96%;
      aspect-ratio: 2.14;
      z-index: -1;
      border-radius: 50%;
      opacity: 0.1;
      background: linear-gradient(270.76deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%);
    }
  }

  // Image should be rendered at 220x150px
  &__image {
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 15px;
  }

  &__tooltip {
    top: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
  }

  &__prev {
    appearance: none;
    border: none;
    padding: 0;
    background: url(../assets/routes/CollectionFamily/arrow-left.png) center center no-repeat;
    background-size: contain;
    width: 25px;
    height: 51px;
    position: absolute;
    top: 50%;
    cursor: pointer;
    margin-top: -25px;
    z-index: 1;

    @include utils.bp-any(sm) {
      top: 30%;
      left: 20px;
    }

    @include utils.bp-any(md, lg) {
      top: 50%;
      left: 0;
    }
  }

  &__next {
    appearance: none;
    border: none;
    padding: 0;
    background: url(../assets/routes/CollectionFamily/arrow-right.png) center center no-repeat;
    background-size: contain;
    width: 25px;
    height: 51px;
    position: absolute;
    cursor: pointer;
    margin-top: -25px;
    z-index: 1;

    @include utils.bp-any(sm) {
      top: 30%;
      right: 20px;
    }

    @include utils.bp-any(md, lg) {
      top: 50%;
      right: 0;
    }
  }
}

.activities {
  &__header {
    background: url(../assets/bg-blue-squishies.png), linear-gradient(180deg, rgba(0, 191, 220, 0.5) -43.68%, rgba(0, 131, 202, 0.5) 100%);
    display: flex;
    flex-direction: column;
    height: 140px;
    margin-top: 80px;

    &::before {
      content: "";
      display: block;
      height: 15px;
      flex-grow: 0;
      @include utils.animated-wave();
    }
  }

  &__headline {
    flex-grow: 1;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Coiny', sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: #FFF;
    text-shadow: 0px 2px 0px rgba(0, 131, 202, 0.5);
    margin-top: 50px;
  }

  &__background {
    display: flow-root;
    background: linear-gradient(90deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%), #FFFFFF;
    position: relative;
    overflow: hidden;

    @include utils.bp-any(sm) {
      padding-bottom: 80px;
    }

    @include utils.bp-any(md, lg) {
      padding-bottom: 150px;
    }

  }

  &__list {
    @include utils.bp-any(sm) {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @include utils.bp-any(md, lg) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  &__promotion {
    @include utils.bp-any(sm) {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @include utils.bp-any(md, lg) {
      margin-top: 60px;
    }
  }
}