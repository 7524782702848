@use '../styles/utils';

.root {
  margin-left: auto;
  margin-right: auto;

  &[data-type="fill"] {
    width: 100%;
  }

  &[data-type="max"] {
    max-width: 1200px;
  }

  &[data-type="wide"] {
    max-width: 1120px;
  }

  &[data-type="compact"] {
    max-width: 1080px;
  }

  &[data-page-margins="true"] {
    &[data-type="max"] {
      max-width: 1260px;
    }

    &[data-type="wide"] {
      max-width: 1180px;
    }

    &[data-type="compact"] {
      max-width: 1140px;
    }

    @include utils.bp-any(sm) {
      padding-left: utils.$pageMarginMobile;
      padding-right: utils.$pageMarginMobile;
    }

    @include utils.bp-any(md, lg) {
      padding-left: utils.$pageMarginDesktop;
      padding-right: utils.$pageMarginDesktop;
    }
  }

  &[data-no-margin-overflow="true"] {
    @include utils.no-margin-overflow();
  }
}