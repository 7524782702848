@use '../styles/utils';

.root {
  position: relative;
}

.middleSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 40px;
}

.circularCountdown {
  width: 100%;
}

.buildStages {
  width: 100%;
}

.description {
  width: 100%;
  color: #002F6C;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-align: left;

  &__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 1.25em;
  }

  &__copy {
    margin-top: 1em;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8em;
    @include utils.no-margin-overflow();
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

@include utils.bp-any(lg) {
  .circularCountdown {
    width: 400px;
  }

  .middleSection {
    flex-wrap: nowrap;
  }

  .buildStages{
    margin-right: 40px;
    width: calc(50% - 250px)
  }

  .description {
    margin-left: 40px;
    width: calc(50% - 250px)
  }

  .buildStages {
    order: 1;
  }

  .circularCountdown {
    order: 2;
  }

  .description {
    order: 3;
  }
}

@include utils.bp-any(md) {
  .middleSection {
    column-gap: 40px;
  }

  .buildStages,
  .description {
    width: calc(50% - 20px);
    max-width: 330px;
  }
}
