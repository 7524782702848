@use '../styles/utils';

.root {
  position: absolute;
  top: calc(50% - 65px);
  left: calc(50% - 180px);
  z-index: 10;
  max-width: 360px;
  width: 100%;

  @include utils.bp-any(sm) {
    top: calc(50% - 53px);
    left: calc(50% - 150px);
    max-width: 300px;
  }
}

.shapeDrag {
  border-radius: 65px;
  background-color: #002f6cd9;
  display: flex;
  align-items: center;
  gap: 23px;
  padding: 48px 50px;

  @include utils.bp-any(sm) {
    padding: 35px 44px;
    gap: 18px;
  }
}

.icon {
  width: 36px;
  height: 36px;

  > path {
    stroke: white;
  }
}

.title {
  font-family: 'Coiny';
  font-size: 22px;
  line-height: 24px;
  color: #fff;
  white-space: pre-wrap;

  @include utils.bp-any(sm) {
    font-size: 18px;
    line-height: 20px;
  }
}

