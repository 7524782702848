@use '../styles/utils';
@use 'sass:math';

.root {
  z-index: 8001;
  background-color: #fff;
  text-align: center;
  overflow-y: auto;
  position: relative;
}

.inner {
  position: relative;
  @include utils.blue-bg();

  @include utils.bp-any(md, lg) {
    overflow: hidden;
  }
}

// On desktop, elements are primarily based on video player sizes of 800x456

.videoContainer {
  display: flow-root;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include utils.bp-any(sm) {
    padding-left: utils.$pageMarginMobile;
    padding-right: utils.$pageMarginMobile;
  }

  @include utils.bp-any(md, lg) {
    margin-top: 70px;
    max-width: 1040px;
  }
}

.logo {
  display: block;

  @include utils.bp-any(sm) {
    max-width: 250px;
    width: 100%;
    margin: 30px auto -5px auto;
  }

  @include utils.bp-any(md, lg) {
    top: percentage(math.div(-125, 456));
    width: percentage(math.div(395, 800));
    height: auto;
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.video {
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include utils.bp-any(md, lg) {
    width: percentage(math.div(800, 1040));
    max-width: 800px;
    margin-top: percentage(math.div(98, 800));

    &::before {
      content: "";
      left: -50vw;
      right: -50vw;
      height: 100%;
      background-color: #FFF;
      display: block;
      position: absolute;
      top: percentage(math.div(400, 456));
    }

    &::after {
      content: "";
      left: -50vw;
      right: -50vw;
      top: calc(#{percentage(math.div(400, 456))} - 15px);
      background-color: red;
      display: block;
      position: absolute;
      height: 15px;
      background: url(../assets/white-wave-up.png) center center repeat-x;
      background-size: auto 100%;
    }
  }
}

.videoPlayer {
  position: relative;
  z-index: 2;
}

.drip {
  @include utils.bp-any(sm) {
    display: none;
  }

  @include utils.bp-any(md, lg) {
    position: absolute;
    bottom: percentage(math.div(-24, 456));
    right: percentage(math.div(55, 800));
    width: percentage(math.div(71, 800));
    height: percentage(math.div(30, 456));
    background: url(../assets/components/WelcomeModal/drip.png) center center no-repeat;
    background-size: 100% 100%;
  }
}

// Mobile characters are based on 312x293
.charactersWrapper {
  display: none;

  @include utils.bp-any(sm) {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    &--below {
      display: block;
    }
  }

  @include utils.bp-any(md, lg) {
    &--around {
      display: block;
    }
  }
}

.characters {
  pointer-events: none;

  @include utils.bp-any(sm) {
    width: 312px;
    height: 293px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 32%;
      left: -50vw;
      right: -50vw;
      height: 100vw;
      background-color: #FFF;
    }

    &::after {
      content: "";
      left: -50vw;
      right: -50vw;
      top: calc(32% - 15px);
      background-color: red;
      display: block;
      position: absolute;
      height: 15px;
      background: url(../assets/white-wave-up.png) center center repeat-x;
      background-size: auto 100%;
    }
  }
}

.mimi {
  display: block;
  aspect-ratio: 164 / 248;
  z-index: 5;

  @include utils.bp-any(sm) {
    position: relative;
    width: percentage(math.div(165, 312));
    margin-left: auto;
    margin-right: auto;
  }

  @include utils.bp-any(md, lg) {
    position: absolute;
    width: percentage(math.div(164, 800));
    top: percentage(math.div(354, 456));
    left: percentage(math.div(-116, 800));
  }
}

.thea {
  aspect-ratio: 129 / 130;
  position: absolute;
  z-index: 6;

  @include utils.bp-any(sm) {
    width: percentage(math.div(125, 312));
    top: percentage(math.div(140, 293));
    right: percentage(math.div(-5, 312));
  }

  @include utils.bp-any(md, lg) {
    width: percentage(math.div(129, 800));
    top: percentage(math.div(526, 456));
    left: percentage(math.div(-2, 800));
  }
}

.bennett {
  aspect-ratio: 129 / 119;
  position: absolute;
  z-index: 7;

  @include utils.bp-any(sm) {
    width: percentage(math.div(130, 312));
    top: percentage(math.div(170, 293));
    left: percentage(math.div(10, 312));
  }

  @include utils.bp-any(md, lg) {
    width: percentage(math.div(129, 800));
    top: percentage(math.div(510, 456));
    right: percentage(math.div(-28, 800));
  }
}

.crate {
  aspect-ratio: 164 / 140;
  position: absolute;
  z-index: 6;

  @include utils.bp-any(sm) {
    display: none;
  }

  @include utils.bp-any(md, lg) {
    width: percentage(math.div(164, 800));
    top: percentage(math.div(438, 456));
    right: percentage(math.div(-85, 800));
  }
}

.content {
  position: relative;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include utils.bp-any(sm) {
    padding-left: utils.$pageMarginMobile;
    padding-right: utils.$pageMarginMobile;
  }

  @include utils.bp-any(md, lg) {
    padding-bottom: 60px;
    max-width: 1040px;
  }
}

.copy {
  position: relative;
  margin: 0 auto;
  margin-top: 15px;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  color: utils.$blue;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;

  @include utils.bp-any(sm) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include utils.bp-any(md, lg) {
    max-width: percentage(math.div(800, 1040));
    padding-left: percentage(math.div(80, 800));
    padding-right: percentage(math.div(80, 800));
  }
}

.button {
  text-transform: uppercase;

  @include utils.bp-any(sm) {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
  }

  @include utils.bp-any(md, lg) {
    margin: 55px auto 0;
  }
}
