@use "../styles/utils";

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.window {
  position: relative;
  max-width: 800px;
  width: 100%;
  background: linear-gradient(180deg, #00BFDC 0%, #0083CA 100%);
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;

  @include utils.bp-any(sm) {
    padding: 30px;
  }

  @include utils.bp-any(md, lg) {
    padding: 40px;
  }
}

.content {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #FFFFFF;
  text-align: center;
  @include utils.no-margin-overflow();
}

.close {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  cursor: pointer;

  @include utils.bp-any(sm, md) {
    &--overlay {
      display: block;
      top: 20px;
      right: 20px;
    }
  }

  @include utils.bp-any(lg) {
    &--window {
      display: block;
      top: -20px;
      right: -20px;
    }
  }
}

.title {
  font-family: 'Coiny';
  font-size: 30px;
  line-height: 1em;
  margin-top: 16px;
  margin-bottom: 16px;

  @include utils.bp-any(sm) {
    font-size: 21px;
  }
}

.description {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  font-size: 14px;
  @include utils.no-margin-overflow();

  a {
    color: #FFF;
  }

  p {
    line-height: 1.4em;
  }

  @include utils.bp-any(sm) {
    font-size: 11px;

    p {
      line-height: 1.2em;
    }
  }
}

.buttons {
  display: flex;
  margin: 30px auto;
  gap: 10px;
  max-width: 340px;
  justify-content: center;

  > button,
  > a {
    width: 100%;
    text-decoration: none;

    > span {
      @include utils.bp-any(sm) {
        font-size: 14px;
      }
    }
  }
}
