@use '../styles/utils';

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - #{utils.$footerScrollButtonHeight});
  background:
    url('../assets/bg-blue-squishies.png'),
    linear-gradient(180deg, #0083ca80 0%, #00bfdc80 100%);

  &[data-show="true"] {
    opacity: 1;
  }

  &[data-show="false"] {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logo {
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: 4;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);

  img {
    display: block;
    margin: 0 auto;

    @include utils.bp-any(md, lg) {
      max-width: 342px;
    }

    @include utils.bp-any(sm) {
      max-width: 254px;
    }
  }
}