@use '../styles/utils';

.root {
  overflow: hidden;
  background-color: #FFF;
}

.inner {
  display: flow-root;

  @include utils.bp-any(sm, md) {
    margin-top: 30px;
  }

  @include utils.bp-any(lg) {
    margin-top: 50px;
  }
}

.header {
  color: #002F6C;
  @include utils.no-margin-overflow();

  @include utils.bp-any(sm) {
    margin-bottom: 30px;
  }

  @include utils.bp-any(md, lg) {
    margin-bottom: 35px;
  }

  &__title {
    font-family: 'Coiny', sans-serif;
    font-size: 30px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    line-height: 33px;
  }

  &__copy {
    text-align: center;
    margin: 0;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 14px;
  }
}

.nav {
  display: flex;
  font-family: 'Coiny', sans-serif;

  &__prev {
    flex-grow: 0;
    flex-shrink: 0;
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;

    @include utils.bp-any(sm) {
      display: none;
    }

    @include utils.bp-any(md, lg) {
      width: 40px;
      height: 50px;
      background: url(../assets/routes/collect/arrow-left.png) no-repeat center center;
      background-size: auto 28px;
    }
  }

  &__carousel {
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      z-index: 5;
      pointer-events: none;
      opacity: 1;

      @include utils.bp-any(sm) {
        width: 30px;
      }

      @include utils.bp-any(md, lg) {
        width: 50px;
      }
    }

    &::before {
      background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
      left: 0;
    }

    &::after {
      background: linear-gradient(-90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
      right: 0;
    }

    @include utils.bp-any(md, lg) {
      margin-left: 20px;
    }
  }

  &__swiperSlide {
    width: auto;
    height: auto;

    &:first-child {
      @include utils.bp-any(sm) {
        margin-left: 30px;
      }

      @include utils.bp-any(md, lg) {
        margin-left: 50px;
      }
    }

    &:last-child {
      @include utils.bp-any(sm) {
        margin-right: 30px;
      }

      @include utils.bp-any(md, lg) {
        margin-right: 50px;
      }
    }
  }

  &__swiperSlideAll {
    @include utils.bp-any(md, lg) {
      margin-right: 20px;
    }
  }

  &__swiperSlideLink {
    background: linear-gradient(270.76deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%);
    text-transform: uppercase;
    border-radius: 999px;
    display: inline-flex;
    text-decoration: none;
    font-weight: 400;
    color: #002F6C;
    column-gap: 14px;
    align-items: center;
    transition: all 0.1s ease-out;

    span {
      margin-top: 0.25em;
    }

    @include utils.bp-any(sm) {
      font-size: 12px;
      padding: 0 16px;
      height: 30px;
    }

    @include utils.bp-any(md, lg) {
      font-size: 16px;
      padding: 0 20px;
      height: 50px;
    }

    &:hover {
      background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
      color: #fff;
    }
  }

  &__swiperSlideLinkActive {
    background: linear-gradient(268.08deg, #00BFDC 2.95%, #0083CA 96.7%);
    color: #FFF;
  }

  &__next {
    flex-grow: 0;
    flex-shrink: 0;
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;

    @include utils.bp-any(sm) {
      display: none;
    }

    @include utils.bp-any(md, lg) {
      width: 40px;
      height: 50px;
      background: url(../assets/routes/collect/arrow-right.png) no-repeat center center;
      background-size: auto 28px;
    }
  }
}

.filter {
  text-align: center;
  font-family: 'Coiny', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #002F6C;
  margin-top: 20px;
  margin-bottom: 40px;

  &__owned {
    display: inline-flex;
    min-height: 30px;
    align-items: center;
    background: linear-gradient(270.76deg, rgba(0, 191, 220, 0.1) 0.64%, rgba(0, 131, 202, 0.1) 99.54%);
    border-radius: 10px;
    padding: 0 12px 0 4px;

    label {
      display: flex;
      align-items: center;
      user-select: none;

      span {
        display: inline-block;
        margin-top: 0.2em;
        margin-left: 0.25em;
      }
    }

    input {
      appearance: none;
      width: 22px;
      height: 22px;
      border: solid 2px #0083CA;
      border-radius: 6px;
      margin: 0 4px 0 0;

      &:checked {
        background: #0083CA url(../assets/routes/collect/check.png) no-repeat center center;
        background-size: 10px auto;
      }
    }
  }
}